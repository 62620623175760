import React from 'react'
import { Auth } from './Auth'
import { ChatBot } from './ChatBot'
import { Navigate, Route, Routes } from 'react-router-dom'

import styles from './App.module.css'

import logo from './logo.svg'
import { useAuthStore } from './context'

const App = () => {
  const token = useAuthStore((state) => state.token)

  return (
    <>
      <div className={styles.preLoader}>
        <img src={logo} alt='Logo' />
      </div>
      <Routes>
        {token ? (
          <Route path='/*' element={<ChatBot />} />
        ) : (
          <>
            <Route path='/' element={<Auth />} />
          </>
        )}
        <Route path='*' element={<Navigate to='/' />} />
      </Routes>
    </>
  )
}

export default App
