import Russian from './assets/russia-flag-icon.svg'
import English from './assets/united-kingdom-flag-icon.svg'

export const LANGUAGE_LIST = [
  {
    id: 'language.english',
    text: 'English',
    IETF: 'en-US',
    LCID: 1033,
    icon: English
  },
  {
    id: 'language.russian',
    IETF: 'ru-RU',
    text: 'Русский',
    LCID: 1049,
    icon: Russian
  }
]
