import React, { Suspense, useEffect, useState } from 'react'
import useWebSocket, { ReadyState } from 'react-use-websocket'
import dayjs from 'dayjs'
import { Chat, ChatInput } from './components'
import c from './ChatBot.module.css'
import { useAuthStore } from '../context'
import { useNavigate } from 'react-router-dom'
import { useQuery } from 'react-query'
import { CircularProgress } from '@mui/material'
import { useTranslation } from 'react-i18next'

type MessageType = 'text' | 'image' | 'audio'

export interface IMessage {
  type: MessageType
  isResponse?: boolean
  content: string
  datetime?: string
}

interface RequestMessage {
  request: {
    type: 'message'
    content: {
      type: MessageType
      text: string
    }
    timestamp: string
  }
}

interface ResponseMessage {
  response: {
    type: 'message'
    content: {
      text: string
      type: 0
      buttons: string[]
      data: string | null
    }
    event: null
    timestamp: string
  }
}

interface IChatHistory {
  user_id: number
  chat_history: {
    content: string
    user: 'human' | 'ai'
    timestamp: string
  }[]
}

// const SOCKET_PATH = 'wss://chat.wiseplus.ru/ws'
// const SOCKET_PATH = 'ws://10.0.40.123:8001/ws'
// const SOCKET_PATH = 'ws://10.2.40.227:8001/ws'

const ChatBot = () => {
  const { i18n } = useTranslation()
  const { token, clearToken } = useAuthStore()

  const navigate = useNavigate()
  const [messages, setMessages] = useState<IMessage[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingHistory, setIsLoadingHistory] = useState(false)
  const [clueButtons, setClueButtons] = useState<string[]>()

  const apiPath = i18n.language === 'en-US' ? 'apien' : 'api'
  const wsPath = i18n.language === 'en-US' ? 'wsen' : 'ws'

  const { sendJsonMessage, readyState, lastJsonMessage } = useWebSocket<ResponseMessage>(
    `wss://chat.wiseplus.ru/${wsPath}` + `?token=${token}`,
    {
      shouldReconnect: () => true
    }
  )

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated'
  }[readyState]

  useEffect(() => {
    const fetchHistory = async () => {
      setIsLoadingHistory(true)
      const now = dayjs()

      try {
        const response = await fetch(`https://chat.wiseplus.ru/${apiPath}/chat_history?limit=50`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`
          }
        })

        if (response.ok) {
          const data = (await response.json()) as IChatHistory

          const forMessages: IMessage[] = data.chat_history.reverse().map((message) => ({
            content: message.content,
            type: 'text',
            datetime: dayjs(message.timestamp).isSame(now, 'day')
              ? dayjs(message.timestamp).format('HH:mm')
              : dayjs(message.timestamp).format('DD.MM'),
            isResponse: message.user === 'ai' ? true : false
          }))

          setMessages(forMessages)
        }
      } catch (error) {
        console.error('History request: ', error)
        setIsLoadingHistory(false)
      }
      setIsLoadingHistory(false)
    }

    fetchHistory()
  }, [])

  useEffect(() => {
    if (readyState === ReadyState.CLOSING || readyState === ReadyState.CLOSED) {
      clearToken()
      navigate('/')
    }
  }, [readyState])

  console.log('connectionStatus', connectionStatus)
  console.log('readyState', readyState)

  useEffect(() => {
    setIsLoading(false)
    // setClueButtons(undefined)

    if (lastJsonMessage !== null && lastJsonMessage) {
      const { response } = lastJsonMessage

      const message: IMessage = {
        content: response.content.text,
        datetime: dayjs().format('HH:mm'),
        isResponse: true,
        type: 'text'
      }

      setMessages((prev) => [message, ...prev])

      if (response.content.buttons) setClueButtons(response.content.buttons)
    }
  }, [lastJsonMessage])

  const handleSendMessage = (message: IMessage) => {
    setMessages([message, ...messages])
    setIsLoading(true)
    setClueButtons(undefined)

    const request: RequestMessage = {
      request: {
        type: 'message',
        content: {
          text: message.content,
          type: 'text'
        },
        timestamp: dayjs().toISOString()
      }
    }

    sendJsonMessage(request)
  }

  return (
    <Suspense fallback={<CircularProgress />}>
      <div className={c.rootApp}>
        <div className={c.chatContainer}>
          <Chat messages={messages} isLoading={isLoading} />
          <ChatInput clueButtons={clueButtons} onSendMessage={handleSendMessage} />
        </div>
      </div>
    </Suspense>
  )
}

export default ChatBot
