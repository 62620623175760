import React, { useState, useEffect } from 'react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import dayjs from 'dayjs'
import { IconButton, TextField } from '@mui/material'
// import { Send as SendIcon, Mic as MicIcon, RadioButtonChecked as RadioButtonCheckedIcon } from '@mui/icons-material'
import { IMessage } from '../../ChatBot'

import c from './ChatInput.module.css'
import { InputButtons } from '../InputButtons'
import { SendIcon } from './assets'
import { useTranslation } from 'react-i18next'

enum MessageTypeEnum {
  Text = 0,
  Audio = 1,
  Image = 2
}

type ResponseMessageType = {
  response: {
    type: 'message' | 'system'
    content?: {
      // если type = 'message' то смотрим content
      message: string
      type: MessageTypeEnum
      data?: string // Любой файл в формате Base64
    }
    event?: {
      // если type = 'system' то смотрим event
      eventName: 'showNotification' | 'createNewEvent' // Любые события которые нужно будет вызвать у пользователя
      eventData: {
        message: string
      }
    }
    timestamp: string
  }
}

type RequestMessageType = {
  payload: {
    type: 'message'
    content: {
      type: MessageTypeEnum
      message?: string
      audio?: string // base64_encoded_audio_data
      file?: {
        fileName: string
        fileData: string // base64_encoded_file_data
      }
    }
    timestamp: string
    userId: string
  }
}

interface ChatInputProps {
  clueButtons?: string[]
  onSendMessage: (message: IMessage) => void
}

const ChatInput: React.FC<ChatInputProps> = ({ clueButtons, onSendMessage }) => {
  const { t } = useTranslation()
  const { startRecording, stopRecording, recordingBlob, isRecording } = useAudioRecorder()

  const [text, setText] = useState('')

  useEffect(() => {
    if (!recordingBlob) return

    const url = URL.createObjectURL(recordingBlob)
    const audio = document.createElement('audio')
    audio.src = url
    audio.controls = true
    // document.body.appendChild(audio);

    onSendMessage({ type: 'audio', content: url })

    // recordingBlob will be present at this point after 'stopRecording' has been called
  }, [recordingBlob])

  const handleSendTextMessage = () => {
    if (text.trim() !== '') {
      onSendMessage({ type: 'text', content: text, datetime: dayjs().format('HH:mm') })
      setText('')
    }
  }

  const onKeyDown = (e: any) => {
    if (e.ctrlKey && e.key === 'Enter') {
      e.preventDefault()

      const { selectionStart, selectionEnd } = e.target
      const newText = text.substring(0, selectionStart) + '\n' + text.substring(selectionEnd)

      setText(newText)

      setTimeout(() => {
        e.target.selectionStart = selectionStart + 1
        e.target.selectionEnd = selectionStart + 1
      }, 0)

      return
    }

    if (e.key === 'Enter') {
      e.preventDefault()

      handleSendTextMessage()
      setText('')
    }
  }

  return (
    <div>
      <InputButtons isOpen={!!clueButtons?.length} buttons={clueButtons} onSendMessage={onSendMessage} />
      <div className={c.inputContainer}>
        <TextField
          classes={{ root: c.input }}
          multiline
          maxRows={4}
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={onKeyDown}
          placeholder={t('chat-bot.chat-input.how-can-we-help')}
        />
        <IconButton className={c.iconBtn} onClick={handleSendTextMessage}>
          <SendIcon viewBox='0 0 21 18' />
        </IconButton>
        {/* {text && !isRecording ? (
        <IconButton className={c.iconBtn} onClick={handleSendTextMessage}>
          <SendIcon />
        </IconButton>
      ) : isRecording ? (
        <IconButton
          className={c.iconBtn}
          //  onMouseDown={startRecording}
          onClick={stopRecording}
        >
          <RadioButtonCheckedIcon />
        </IconButton>
      ) : (
        <IconButton
          className={c.iconBtn}
          onClick={startRecording}
          //  onMouseUp={stopRecording}
        >
          <MicIcon />
        </IconButton>
      )} */}
      </div>
    </div>
  )
}

export default ChatInput
