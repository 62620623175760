import React, { useState } from 'react'
import { TextField, Typography, Box } from '@mui/material'

import styles from './Auth.module.css'
import { useAuthStore } from '../context'
import { Button } from '../shared-ui'
import { SelectLangComponent } from '../shared'
import { useTranslation } from 'react-i18next'

type ErrorsType = {
  login?: string
  password?: string
}

const Auth = () => {
  const { t, i18n } = useTranslation()
  const { setToken } = useAuthStore((store) => store)

  const [login, setLogin] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [errors, setErrors] = useState<ErrorsType>({})
  const [requestError, setRequestError] = useState<string>()
  const [isLoading, setIsLoading] = useState(false)

  const apiPath = i18n.language === 'en-US' ? 'apien' : 'api'

  const validate = () => {
    const newErrors: ErrorsType = {}
    if (!login?.trim()) newErrors.login = t('auth.login.login-required')
    if (!password?.trim()) newErrors.password = t('auth.login.password-required')

    return newErrors
  }

  const serviceUnavailable = t('auth.login.service-unavailable')
  const userNotFound = t('auth.login.user-not-found')

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    const validationErrors = validate()
    setErrors(validationErrors)

    if (Object.keys(validationErrors).length === 0) {
      setIsLoading(true)
      setRequestError(undefined)

      try {
        const response = await fetch(`https://chat.wiseplus.ru/${apiPath}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            login,
            authWord: password
          })
        })

        const payload = (await response.json()) as { result?: boolean; detail?: string }

        if (payload.result) {
          const authHeader = response.headers.get('Authorization')

          if (authHeader) {
            const token = authHeader.split(' ')[1]

            sessionStorage.setItem('bearer', token)
            setToken(token)
          } else {
            setRequestError(serviceUnavailable)
            return
          }
          setRequestError(userNotFound)
          return
        }

        setRequestError(payload.detail)
        setIsLoading(false)
      } catch (error) {
        console.error('Fetch error: ', error)

        setRequestError(serviceUnavailable)
        setIsLoading(false)
      }
    }
  }

  return (
    <div className={styles.root}>
      <SelectLangComponent className={styles.selectLang} />
      <Box component='form' onSubmit={handleSubmit} sx={{ mt: 8, display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant='h4' gutterBottom align='center'>
          {t('auth.login.authorization')}
        </Typography>
        <TextField
          required
          className={styles.input}
          label={t('auth.login.login')}
          value={login}
          type='text'
          onChange={(e) => setLogin(e.target.value)}
          error={!!errors.login}
          // helperText={errors.login || ' '}
          fullWidth
        />
        <TextField
          required
          className={styles.input}
          label={t('auth.login.password')}
          type='password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={!!errors.password || !!errors.login}
          helperText={errors.login || errors.password ? [errors.login, errors.password].join('. ') : ' '}
          fullWidth
        />
        <Button
          className={styles.button}
          type='submit'
          size='large'
          variant='contained'
          color='primary'
          fullWidth
          loading={isLoading}
        >
          {t('auth.login.enter')}
        </Button>
        {requestError && <span className={styles.error}>{requestError}</span>}
      </Box>
    </div>
  )
}

export default Auth
