import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'

import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material'
import { IconButton, Menu, MenuItem } from '@mui/material'

import { LANGUAGE_LIST } from './constant'
import styles from './SelectLangComponent.module.css'

interface IProps {
  className?: string
}

const SelectLangComponent: React.FC<IProps> = ({ className }) => {
  const { i18n } = useTranslation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const onClick = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(e.currentTarget)
  const onClose = () => setAnchorEl(null)

  const selectedLang = LANGUAGE_LIST.find((lang) => lang.IETF === i18n.language) ?? LANGUAGE_LIST[1]

  const selectCulture = (IETF: string) => {
    i18n.changeLanguage(IETF)
  }

  return (
    <div className={className}>
      <IconButton onClick={onClick} className={styles.iconBtn}>
        <div className={styles.langIcon}>
          <img src={selectedLang.icon} alt={selectedLang.text} height={24} />
        </div>
        <span>{selectedLang.text.slice(0, 2)}</span>
        <ArrowDropDownIcon />
      </IconButton>
      <Menu open={open} anchorEl={anchorEl} onClose={onClose}>
        {LANGUAGE_LIST.map(({ IETF, LCID, text, icon }) => (
          <MenuItem key={LCID} className={styles.item} value={IETF} onClick={() => selectCulture(IETF)}>
            <div className={styles.langIcon}>
              <img src={icon} height={24} alt={text} />
            </div>
            {text}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}

SelectLangComponent.displayName = 'SelectLangComponent'

export default SelectLangComponent
