import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import enTranslation from './keysets/keyset.en-US.json'
import ruTranslation from './keysets/keyset.ru-RU.json'

export const defaultNS = 'ruTranslation'

export const resources = {
  'en-US': { translation: enTranslation },
  'ru-RU': { translation: ruTranslation }
} as const

export type LanguageResourcesType = keyof typeof resources

i18n.use(LanguageDetector).use(initReactI18next).init({
  // lng: 'ru-RU',
  // debug: process.env.NODE_ENV !== 'production',
  fallbackLng: 'ru-RU',
  resources
})

export default i18n
